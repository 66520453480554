<template>
  <div
    class="overflow-x-auto overflow-y-hidden lg:overflow-x-hidden flex gap-3 pb-2 fw-scrollbar xl:grid xl:grid-cols-5"
  >
    <div
      v-for="(dayEvents, isoWeekday) in eventsByDay"
      :key="`weekday_${isoWeekday}`"
      class="bg-white rounded-lg px-2 pb-3 pt-1.5 weeday-box border flex flex-col flex-1"
      :class="{
        'border-teal-500': todayWeekDay == isoWeekday && isCurrentWeek,
        'border-white': todayWeekDay != isoWeekday || !isCurrentWeek,
      }"
    >
      <div class="font-semibold text-md pb-2">
        {{ todayWeekDay == isoWeekday && isCurrentWeek ? $t('today') + ',' : '' }} {{ $t(`weekdays.${isoWeekday}`) }}
        <div v-if="dayEvents[0]" class="font-semibold text-xs text-gray-500">
          {{ dayEvents[0].date | formatDate }}
        </div>
        <div v-else-if="showAllDates" class="font-semibold text-xs text-gray-500">
          {{ datesByDay[isoWeekday] | formatDate }}
        </div>
      </div>
      <div class="flex flex-col gap-2 flex-1">
        <slot name="events" :events="dayEvents"></slot>
        <div
          v-if="!dayEvents[0]"
          class="text-center text-gray-400 py-10 text-xs flex flex-col gap-2 items-center justify-center"
        >
          <div><fw-icon-sun class="w-6 h-6" /></div>
          <div>{{ $t('noEvents') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'

export default {
  name: 'WeekView',
  props: {
    events: {
      type: Array,
      default: () => [],
    },
    showWeekend: {
      type: Boolean,
      default: false,
    },
    isCurrentWeek: {
      type: Boolean,
      default: true,
    },
    showAllDates: {
      type: Boolean,
      default: true,
    },
    selectedWeekNumber: {
      type: Number,
      default: -1,
    },
    selectedYear: {
      type: Number,
      default: new Date().getFullYear(),
    },
  },
  data() {
    return {
      showAllDayEvents: true, // show all-day events by default, hide them if not needed
      eventsByDay: {
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        7: [],
      },
      datesByDay: {
        1: null,
        2: null,
        3: null,
        4: null,
        5: null,
        6: null,
        7: null,
      },
    }
  },
  computed: {
    todayWeekDay() {
      return Dates.now().isoWeekday()
    },
  },
  watch: {
    events(newVal, oldVal) {
      if (newVal != oldVal) {
        this.sortEvents()
      }
    },
    selectedWeekNumber() {
      this.getDaysOfTheWeek()
    },
  },
  mounted() {
    this.sortEvents()
    this.getDaysOfTheWeek()
  },
  methods: {
    openEventLink(link) {
      if (typeof link == 'string') {
        this.$router.push(link)
      }
    },
    getDaysOfTheWeek() {
      if (!this.showAllDates) return
      const date = Dates.now()
        .isoWeek(this.selectedWeekNumber)
        .set('year', this.selectedYear)

      this.datesByDay = {
        1: date.isoWeekday(1).toDate(),
        2: date.isoWeekday(2).toDate(),
        3: date.isoWeekday(3).toDate(),
        4: date.isoWeekday(4).toDate(),
        5: date.isoWeekday(5).toDate(),
        6: date.isoWeekday(6).toDate(),
        7: date.isoWeekday(7).toDate(),
      }
    },
    sortEvents() {
      let showAllDayEvents = false
      let orderedEvents = JSON.parse(JSON.stringify(this.events))

      orderedEvents.sort((a, b) => {
        //1. Put all events without hour at the start of the list
        if (typeof a['hour'] === 'undefined' || a['hour'] == '' || a['hour'] == null) {
          showAllDayEvents = true
          return 1 // a comes before b
        }
        if (typeof b['hour'] === 'undefined' || b['hour'] == '' || b['hour'] == null) {
          showAllDayEvents = true
          return -1 // b comes before a
        }
        let dateCompare = b['date'].localeCompare(a['date'])
        if (dateCompare == 0) {
          //order by hour
          return b['time'].localeCompare(a['hour'])
        } else {
          return dateCompare
        }
      })

      // 1 = Monday, 2 = Tuesday, 3 = Wednesday, 4 = Thursday, 5 = Friday, 6 = Saturday, 7 = Sunday
      let eventsByDay = { 1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [] }

      for (const ev of orderedEvents) {
        //console.log(ev)
        if (typeof ev['date'] == 'string') {
          eventsByDay[Dates.buildCore(ev['date']).isoWeekday()].push(ev)
        }
      }

      if (!this.showWeekend) {
        delete eventsByDay[7] //delete sunday
        delete eventsByDay[6] //delete saturday
      }
      this.$set(this, 'eventsByDay', eventsByDay)

      this.showAllDayEvents = showAllDayEvents
    },
  },
}
</script>

<style lang="postcss" scoped>
.weeday-box {
  min-width: 175px;
}
.eventbox {
  @apply rounded-lg px-2 py-1.5 text-sm font-semibold text-white;
}
.eventbox.eventbox-light {
  @apply bg-gray-200 text-gray-700 bg-opacity-60;
}
.eventbox.eventbox-transparent {
  @apply text-gray-500;
}
.eventbox:hover {
  @apply opacity-80;
}
.eventbox.has_link:hover {
  @apply shadow-sm cursor-pointer;
}
.event-separator {
  height: 7px;
  min-height: 7px;

  @apply border-b border-gray-100;
}
</style>

<i18n>
{
  "en": {
    "noEvents": "No events",
    "today": "Today",
    "weekdays": {
      "7": "Sunday",
      "1": "Monday",
      "2": "Tuesday",
      "3": "Wednesday",
      "4": "Thursday",
      "5": "Friday",
      "6": "Saturday"
    }
  },
  "pt": {
    "noEvents": "Sem eventos",
    "today": "Hoje",
    "weekdays": {
      "7": "Domingo",
      "1": "Segunda-feira",
      "2": "Terça-feira",
      "3": "Quarta-feira",
      "4": "Quinta-feira",
      "5": "Sexta-feira",
      "6": "Sábado"
    }
  }
}
</i18n>
