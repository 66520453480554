<template>
  <div class="flex">
    <div v-if="loading" class="h-5 w-5 relative self-center mr-3">
      <svg
        class="loading fill-current text-gray-50"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
      >
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M18.364 5.636L16.95 7.05A7 7 0 1 0 19 12h2a9 9 0 1 1-2.636-6.364z" />
      </svg>

      <svg
        class="loading fill-current text-gray-500"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
      >
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M12 3a9 9 0 0 1 9 9h-2a7 7 0 0 0-7-7V3z" />
      </svg>
    </div>
    <button
      :disabled="!hasPrevious"
      class="previous shadow-anim"
      :class="{ active: hasPrevious, disabled: !hasPrevious }"
      @click="previous"
    >
      <fw-icon-chevron-left class="w-7 h-7" />
      <div v-if="previousText" class="pl-2 pr-2 self-center">
        {{ previousText }}
      </div>
    </button>
    <button
      v-if="showReset"
      class="reset shadow-anim active"
      :class="{ active: hasReset, disabled: !hasReset }"
      @click="reset"
    >
      <fw-icon-rec class="w-5 h-5" />
    </button>
    <button
      :disabled="!hasNext"
      class="next shadow-anim"
      :class="{ active: hasNext, disabled: !hasNext }"
      @click="next"
    >
      <div v-if="nextText" class="pr-2 pl-2 self-center">
        {{ nextText }}
      </div>
      <fw-icon-chevron-right class="w-7 h-7" />
    </button>
  </div>
</template>
<script>
export default {
  name: 'NextPreviousButtons',
  props: {
    nextText: {
      type: String,
      default: null,
    },
    previousText: {
      type: String,
      default: null,
    },
    hasReset: {
      type: Boolean,
      default: false,
    },
    showReset: {
      type: Boolean,
      default: false,
    },
    hasNext: {
      type: Boolean,
      default: false,
    },
    hasPrevious: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    next() {
      if (this.hasNext) {
        this.$emit('next')
      }
    },
    previous() {
      if (this.hasPrevious) {
        this.$emit('previous')
      }
    },
    reset() {
      this.$emit('reset')
    },
  },
}
</script>
<style scoped>
.loading {
  @apply absolute top-1/2 left-1/2 -ml-3 -mt-3 h-6 w-6;
  animation: loadinganim 600ms infinite linear;
}

@keyframes loadinganim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

button {
  margin: 0;
  padding: 0;
  border: 0;
  cursor: pointer;
  outline: none;
  @apply flex content-center font-semibold px-1.5 py-1 bg-white text-gray-700 text-opacity-75 text-sm items-center;
}

button.previous {
  @apply rounded-l-md;
}
button.reset {
  @apply rounded-none;
}
button.next {
  @apply rounded-r-md;
}
button.disabled {
  @apply opacity-60 cursor-not-allowed text-gray-700 text-opacity-50;
}

button.disabled svg {
  opacity: 0.6;
}

.shadow-anim {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}
/* Pre-render the bigger shadow, but hide it */
.shadow-anim:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Transition to showing the bigger shadow on hover */
.shadow-anim:hover::after {
  opacity: 1;
}
</style>
